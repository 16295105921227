.veriContainer{
    padding: 2rem;
    display: flex;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
}
.leftveri{
    width: 50%;
}
.overlay {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit below the modal */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
  }
.rightveri{
    width: 50%;
    text-align: center;
    padding: 3rem;
}
.cardselect{
    width: 80%;
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
    border: solid 1px;
    outline: solid 2px;
}
.redemptio{
    width: 80%;
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
}
.selectcurrency{
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
    border: solid 1px;
    outline: solid 2px;
}
.sect2{
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
/* .se */
.amount{
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 3px;
    width: 80%;
}
.warning{
    color: rgb(145, 145, 5);
}
.verifyCard{
    width: 80%;
    padding: 0.5rem;
    background-color:#198754;
    color: #fff;
    font-size: large;
    border-radius: 6px;
    cursor: pointer;
}
.warningp{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
@media screen and (max-width:768px) {
    .veriContainer{
        flex-direction: column;
    }
    .leftveri{
        width: 90%;
        padding: 0px;
    }
    .verimg{
        width: 90%;
    }
    .rightveri{
        width: 100%;
        padding: 0.5rem;
    }
   
    .cardselect{
        width: 100%;
        padding: 1rem;
        border: none;
        appearance: none;
        font-size: 20px;
    }
    .redemptio{
        width: 100%;
        padding: 1rem;
        font-size: 20px;
    }
    .sect2{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .selectcurrency{
        padding: 1rem;
        border: none;
        appearance: none;
        font-size: 20px;
        /* width: 18%; */
    }
    .amount{
        width: 70%;
        font-size: 20px;
    }
    .verifyCard{
        width: 100%;
        padding: 1rem;
        font-size: 20px;
        border-radius: 6px;
    }
    /* select > option{
        padding: 1rem;
    } */
}
