.modalcontainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 2px 2px 2px 3px gray;
    text-align: center;
    width: 50%;
    padding: 2rem;
    z-index: 1001;
}
.modalcontainer >h1{
    color: green;
    margin: 2rem;
}
.modalcontainer >h2{
    margin: 1rem;
}
.modalcontainer >div{
    margin: 1rem;
}
.closeBtn{
    padding: 0.8rem 1.2rem;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
}
@media screen and (max-width:768px) {
    .modalcontainer{
        width: 90%;
    }
}